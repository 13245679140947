<template>
  <UiLinkVLink
    :type="type"
    :class="classNames($style.Button, mods)"
    :disabled="disabled || loading"
  >
    <slot>
      <span v-if="label">
        {{ label }}
      </span>
    </slot>
  </UiLinkVLink>
</template>

<script setup lang="ts">
export interface Props {
  type?: "button" | "submit" | "reset";
  color?: "primary" | "secondary";
  size?: "small" | "medium" | "tab";
  block?: boolean;
  label?: string | null;
  disabled?: boolean;
  active?: boolean;
  loading?: boolean;
  leadingIcon?: object | null;
  trailingIcon?: object | null;
}

const props = withDefaults(defineProps<Props>(), {
  type: "button",
  color: "primary",
  size: "medium",
  label: null,
  block: false,
  disabled: false,
  active: false,
  loading: false,
  leadingIcon: null,
  trailingIcon: null,
});

const style = useCssModule();
const mods = computed(() => ({
  [style[props.color]]: true,
  [style[props.size]]: true,
  [style.block]: props.block,
  [style.disabled]: props.disabled || props.loading,
  [style.active]: props.active,
}));
</script>

<style lang="scss" module>
.Button {
  $block: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  text-align: center;
  transition: all $default-transition;
  cursor: pointer;
  user-select: none;
}

.small {
  height: 4rem;
  padding: 0 3.5rem;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
}

.medium {
  height: 5.8rem;
  padding: 0 7rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1;

  @include respond-to(sm) {
    height: 51px;
    padding: 0 50px;
    font-size: 15px;
  }

  @include respond-to(xs) {
    height: 40px;
    padding: 0 35px;
    font-size: 12px;
  }
}

.tab {
  height: 4.2rem;
  padding: 0 2rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 1;

  @include respond-to(xs) {
    height: 29px;
    padding: 0 12px;
  }

  @include respond-to(xs) {
    font-size: 14px;
  }
}

.primary {
  font-family: $font-family-secondary;
  background-color: $bg-btn-active;
  box-shadow: 0 7px 0 0 #6f880b;
  color: #121212;

  @include respond-to(sm) {
    box-shadow: 0 6px 0 0 #6f880b;
  }

  @include respond-to(xs) {
    box-shadow: 0 4px 0 0 #6f880b;
  }

  &:hover {
    background-color: $bg-btn-hover;
  }

  &:focus-within {
    background-color: $bg-btn-hover;
  }

  &:disabled {
    background-color: $bg-btn-disabled;
    box-shadow: 0 7px 0 0 #202020;
    color: #929292;

    @include respond-to(sm) {
      box-shadow: 0 6px 0 0 #202020;
    }

    @include respond-to(xs) {
      box-shadow: 0 4px 0 0 #202020;
    }
  }
}

.secondary {
  border-radius: 200px;
  border: 1px solid $color-border;

  &.active {
    background-color: $brand-green;
    border: 1px solid $brand-green;
    color: $base-1000;
  }
}

.disabled {
  cursor: not-allowed;
}

.block {
  width: 100%;
}
</style>
