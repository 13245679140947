import { useAuthApi } from "~/api/useAuthApi";

export function useUser() {
  const user = useState<any>("user", () => null);
  const accessToken = useState<string | null>("accessToken", () => null);

  const isLogged = computed(() => {
    return !!user.value;
  });
  const { refresh, fetchUser } = useAuthApi();
  const getToken = async () => {
    const { data, error } = await refresh();

    if (error.value) {
      // handle logout
      return;
    }
    accessToken.value = data.value;
  };

  const getUser = async () => {
    if (!accessToken.value) {
      return;
    }

    const { data, error } = await fetchUser();
    if (error.value) {
      return;
    }
    user.value = data.value;
  };

  const email = computed(() => user.value?.email);
  const isActivated = computed(() => user.value?.activated);

  return {
    user,
    accessToken,
    isLogged,
    getToken,
    getUser,
    email,
    isActivated,
  };
}
