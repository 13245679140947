import { useApiFetch, useAuthApiFetch, useBasedFetch } from "~/api/useApi";
import { Direction, PairData, Reserve, Filter } from "~/types/calculator";

const URLS = {
  calculatorUrl: "/api/v1/calculator/",
  courseUrl: (from: number, to: number) =>
    `/api/v1/calculator/course/${from}/${to}/`,
  fromIdsUrl: "/api/v1/calculator/from/",
  toIdsUrl: (fromId: number) => `/api/v1/calculator/from/${fromId}/`,
  pairUrl: (fromId: number | null, toId: number | null) =>
    `/api/v1/calculator/pair/${fromId}/${toId}/`,
  reservesUrl: "/api/v1/calculator/reserves/",
  settingsUrl: (from: string, to: string) =>
    `/api/v1/calculator/settings/?cur_from=${from}&cur_to=${to}`,
  techUrl: "/api/v1/calculator/tech/",
  getSettingsUrl: "/api/v1/calculator/settings/",
  getChatUrl: "/api/v1/calculator/chat/",
};

interface Tech {
  time: number;
  on: boolean;
}

interface ExchangeAttributes {
  fromCurrencyId: number;
  toCurrencyId: number;
}

interface fetchSettingsResponse {
  filters: {
    main: Filter[];
    alternative: Filter[];
  };
  contest: boolean;
}

interface fetchChatResponse {
  src: string;
  async: boolean;
}

export const useCalculatorApi = () => {
  const fetchDirections = () => {
    return useBasedFetch<Direction[]>(URLS.calculatorUrl);
  };

  const fetchFromCurrenciesIds = () => {
    return useApiFetch<number[]>(URLS.fromIdsUrl);
  };

  const fetchToCurrenciesIds = (fromId: number) => {
    return useApiFetch<number[]>(URLS.toIdsUrl(fromId));
  };

  const fetchPair = (fromId: number | null, toId: number | null) => {
    return useAuthApiFetch<PairData>(URLS.pairUrl(fromId, toId));
  };

  const fetchPairShop = (fromId: number | null, toId: number | null) => {
    return useApiFetch<PairData>(URLS.pairUrl(fromId, toId));
  };

  const fetchCourse = (from: number, to: number) => {
    return useApiFetch<{ course: number }>(URLS.courseUrl(from, to));
  };

  const fetchReserves = () => {
    return useBasedFetch<Reserve[]>(URLS.reservesUrl);
  };
  const fetchSettings = (from: string, to: string) => {
    return useApiFetch<{
      filters: any;
      exchangeAttributes: ExchangeAttributes;
    }>(URLS.settingsUrl(from, to));
  };

  const fetchTech = () => {
    return useApiFetch<{ technicalMode: Tech }>(URLS.techUrl);
  };

  const fetchSetting = () => {
    return useBasedFetch<fetchSettingsResponse>(URLS.getSettingsUrl, {
      lazy: true,
      pick: ["filters", "contest"],
    });
  };

  const fetchChat = () => {
    return useBasedFetch<fetchChatResponse>(URLS.getChatUrl, {
      lazy: true,
    });
  };

  return {
    fetchDirections,
    fetchFromCurrenciesIds,
    fetchToCurrenciesIds,
    fetchPair,
    fetchCourse,
    fetchReserves,
    fetchSettings,
    fetchTech,
    fetchSetting,
    fetchChat,
    fetchPairShop,
  };
};
