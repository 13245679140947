import { storeToRefs } from "pinia";
import { useDirectionsStore } from "~/stores/directionsStore";
import { useCalculatorStore } from "~/stores/calculatorStore";
import { useClaimApi } from "~/api/useClaimApi";
import { useCalculatorApi } from "~/api/useCalculatorApi";
import type { claimBody } from "~/types/calculator";

export function useCalculator() {
  const directionsStore = useDirectionsStore();

  const {
    fromCurrencyId,
    toCurrencyId,
    fromDirections,
    toDirections,
    fromDirection,
    toDirection,
    directions,
  } = storeToRefs(directionsStore);

  const {
    getDirections,
    init,
    onCurrencyChange,
    getReserves,
    getReserveById,
    setToCurrencyId,
    setFromCurrencyId,
    onCurrenciesChange,
  } = directionsStore;

  const calculatorStore = useCalculatorStore();
  const {
    email,
    formInputs,
    course,
    formValues,
    filteredFormValues,
    fromOptions,
    toOptions,
    fromSavedRequisites,
    toSavedRequisites,
    fromValue,
    toValue,
    formInputsMeta,
    referral,
    discount,
    congestion,
    saveRequisites,
    promocode,
    pairData,
  } = storeToRefs(calculatorStore);
  const { getCourse, handleInput, handleChange, getData, setPromocode } =
    calculatorStore;

  const onFromCurrencyChange = (id: number) => {
    return onCurrencyChange("from", id);
  };

  const onToCurrencyChange = (id: number) => {
    return onCurrencyChange("to", id);
  };

  const handleInputFrom = (value: number) => {
    return handleInput("from", value);
  };

  const handleInputTo = (value: number) => {
    return handleInput("to", value);
  };

  const handleChangeFrom = (value: number) => {
    return handleChange("from", value);
  };

  const handleChangeTo = (value: number) => {
    return handleChange("to", value);
  };

  const courseString = computed(() => {
    return course.value ? course.value.toString() : "";
  });

  const fromSumma = roundNumber(
    fromValue.value ?? 0,
    formInputsMeta?.value.from?.round ?? 2,
  );
  const toSumma = roundNumber(
    toValue.value ?? fromValue.value * course.value,
    formInputsMeta?.value.to?.round ?? 2,
  );

  function createClaim() {
    if (!fromDirection.value || !toDirection.value) {
      return;
    }
    const data: claimBody = {
      email: email.value,
      fromPs: fromDirection.value.id,
      toPs: toDirection.value.id,
      fromSumma,
      toSumma,
      fromRequisites: filteredFormValues.value.from,
      toRequisites: filteredFormValues.value.to,
    };
    if (referral.value) {
      data.refferal = referral.value;
    }
    if (promocode.value) {
      data.promocode = promocode.value;
    }
    if (saveRequisites.value) {
      data.saveRequisites = saveRequisites.value;
    }
    return useClaimApi().createClaim(data);
  }

  async function setInitialCurrencies() {
    const { query } = useRoute();
    if (!query.cur_from || !query.cur_to) {
      return;
    }
    try {
      const fromCurrency = query.cur_from as string;
      const toCurrency = query.cur_to as string;
      const { exchangeAttributes } = await useCalculatorApi().fetchSettings(
        fromCurrency,
        toCurrency,
      );
      if (!exchangeAttributes) {
        return;
      }
      const { toCurrencyId, fromCurrencyId } = exchangeAttributes;
      setFromCurrencyId(fromCurrencyId);
      setToCurrencyId(toCurrencyId);
    } catch (error) {
      throw new Error("Error while setting initial currencies", error);
    }
  }

  const notifications = computed(() => {
    if (!pairData.value) return null;
    const notifications = [];
    if (pairData.value.from.notify) {
      notifications.push({
        currency: fromDirection.value?.name,
        message: pairData.value.from.notify,
      });
    }
    if (pairData.value.to.notify) {
      notifications.push({
        currency: toDirection.value?.name,
        message: pairData.value.to.notify,
      });
    }
    return notifications;
  });

  return {
    setInitialCurrencies,
    getDirections,
    getReserves,
    init,
    onFromCurrencyChange,
    onToCurrencyChange,
    getReserveById,
    fromDirections,
    toDirections,
    fromDirection,
    toDirection,
    directions,

    email,
    formInputs,
    notifications,
    course,
    formValues,
    getCourse,
    courseString,
    fromOptions,
    toOptions,
    fromSavedRequisites,
    toSavedRequisites,

    createClaim,
    fromValue,
    toValue,
    formInputsMeta,
    handleInputFrom,
    handleInputTo,
    handleChangeFrom,
    handleChangeTo,

    fromCurrencyId,
    toCurrencyId,
    onCurrenciesChange,

    referral,
    saveRequisites,
    setFromCurrencyId,
    setToCurrencyId,
    getData,
    filteredFormValues,
    discount,
    congestion,
    promocode,
    setPromocode,
  };
}
